@import 'src/styles/variables';

.list {
  display: flex;
  max-width: 1200px;
  margin: auto;
  flex-grow: 1;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  width: 100%;

  @include for-size(tablet-landscape-down) {
    justify-content: center;
  }

  &.newList {
    justify-content: space-between;

    @include for-size(tablet-landscape-down) {
      justify-content: center;
    }
  }
}

.image {
  color: $white;
  line-height: 0;
  // margin: 10px;
}

.fixedImage {
  color: $white;
  line-height: 0;
}

@include for-size(tablet-portrait-down) {
  .axa {
    height: 50px;
    width: 46.67px;
  }

  .expedia {
    height: 50px;
    width: 90px;
  }

  .samsung {
    height: 50px;
    width: 104px;
  }

  .marriott {
    height: 50px;
    width: 56.67px;
  }

  .philips {
    height: 50px;
    width: 90px;
  }

  .unilever {
    height: 50px;
    width: 44.67px;
  }

  .roche {
    height: 50px;
    width: 66.67px;
  }

  .shopify {
    height: 50px;
    width: 110px;
  }
  .mmm {
    height: 50px;
    width: 44.67px;
  }
}

@include for-size(tablet-portrait-down) {
  .axaFixed {
    height: 30px;
    width: 30px;
  }

  .philipsFixed {
    height: 30px;
    width: 24px;
  }

  .unileverFixed {
    height: 30px;
    width: 27.75;
  }

  .rocheFixed {
    height: 30px;
    width: 58.5px;
  }

  .shopifyFixed {
    height: 30px;
    width: 103.5px;
  }

  .mmmFixed {
    height: 30px;
    width: 57.75;
  }
}

.imageContainer {
  text-align: center;
  margin-right: 30px;
  margin-left: 30px;
  line-height: 0;
  @include for-size(desktop-down) {
    margin-right: 28px;
    margin-left: 28px;
  }

  @include for-size(tablet-portrait-down) {
    margin-right: -5px;
    margin-left: -5px;
  }

  &.fixedContainer {
    margin-bottom: 15px;
    margin-right: 40px;
    margin-left: 40px;

    @include for-size(tablet-portrait-down) {
      // margin-right: 15px;
      // margin-left: 15px;
      margin: 15px;
    }
  }

  &.newContainer {
    &:first-of-type {
      margin-left: 0;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }
}
